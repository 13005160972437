import { Menu } from "lucide-react";
import Link from "next/link";
import { useState } from "react";
export function Nav() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return <header className="sticky top-0 z-40 w-full bg-white" data-sentry-component="Nav" data-sentry-source-file="Nav.tsx">
      <div className="mx-auto border-b border-gray-300  h-14 md:border-b-0 pb-3">
        <div className="h-14 px-4 pt-3 pb-3 flex justify-between ">
          <Link href="/" className="ml-2 text-xl flex " data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
            <div className="font-bold text-white bg-black hover:bg-hover-black w-8 h-8 items-center  justify-center flex  rounded">
              K
            </div>
            <div className="font-bold ml-3 hover:text-hover-black text-black mt-[2px] ">
              Klarman
            </div>
          </Link>
          <nav className="hidden md:flex gap-2 pt-[15px] h-10 ">
            {/* <Link href="/" className="ml-2 flex ">
              <div className="ml-1 text-black hover:bg-sidebar-custom  px-2 rounded ">
                Product
              </div>
             </Link>
             <Link href="/" className="ml-2 flex ">
              <div className="ml-1 text-black hover:bg-sidebar-custom px-2 rounded ">
                Collaboration
              </div>
             </Link>
             <Link href="/pricing" className="ml-2 flex  ">
              <div className="ml-1 text-black hover:bg-sidebar-custom px-2 rounded ">
                Pricing
              </div>
             </Link> */}
          </nav>
          <span className="flex md:hidden ">
            <button onClick={() => setIsOpen(!isOpen)} className="">
              <Menu color="gray" data-sentry-element="Menu" data-sentry-source-file="Nav.tsx" />
            </button>

            {isOpen && <div className="absolute text-black right-[18px] top-9 mt-5 w-48 bg-white shadow-md border border-gray-200 rounded-xl">
                {/* <Link
                  href="/"
                  className="rounded-tl-lg rounded-tr-lg p-2 flex hover:bg-gray-200"
                 >
                  <div className="ml-1 text-black">Product</div>
                 </Link>
                 <Link href="/" className="p-2 flex hover:bg-gray-200">
                  <div className="ml-1 text-black ">Collaboration</div>
                 </Link>
                 <Link href="/pricing" className=" p-2 flex hover:bg-gray-200">
                  <div className="ml-1 text-black  ">Pricing</div>
                 </Link> */}
                <Link href="/login" className="text-black p-2 flex rounded-tl-lg rounded-tr-lg  hover:bg-gray-200">
                  <div className="ml-1 text-black  ">Log in</div>
                </Link>
                <Link href="https://airtable.com/apptQRcMxe1V2DXMh/pag2LjGPr51DuggPs/form" className="text-black p-2 flex rounded-bl-lg rounded-br-lg  hover:bg-gray-200" target="_blank">
                  <div className="ml-1 ">Request Access</div>
                </Link>
              </div>}
          </span>
          <div className="hidden md:flex gap-2 ">
            <Link href="https://airtable.com/apptQRcMxe1V2DXMh/pag2LjGPr51DuggPs/form" className="inline-flex items-center justify-center hover:bg-hover-black bg-black text-white rounded-[0.3125rem] min-h-[40px] font-medium p-2  mr-1" target="_blank" data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
              <div>Request Access</div>
            </Link>
            <div className="bg-gray-300 mt-1  w-[1px] h-[30px]"></div>

            <Link href="/login" className={`text-black ml-1  h-[40px] p-2 hover:bg-sidebar-custom  rounded-[0.3125rem]  `} data-sentry-element="Link" data-sentry-source-file="Nav.tsx">
              Log in
            </Link>
          </div>
        </div>
      </div>
    </header>;
}