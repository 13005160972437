"use client";

import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import WhiteboardImg from "@/public/icons/whiteboard-img.svg";
import KlarmanAiImg from "@/public/icons/brain.png";
import CollaborateImg from "@/public/icons/collaborate-img.svg";
import WhiteboardCard from "@/public/icons/WhiteboardCard.png";
import ExportIcon from "@/public/icons/exporticon.png";
import KlarmanAICard from "@/public/icons/KlarmanAICard.png";
import MetaIcon from "@/public/icons/meta-icon.png";
import OpenaiIcon from "@/public/icons/openai-icon.png";
import AnthropicIcon from "@/public/icons/anthropic-icon.png";
import CollaborationCard from "@/public/icons/CollaborationCard.png";
import { Nav } from "@/components/Nav";
import { Footer } from "@/components/Footer";
export default function Home() {
  const [imageSrc, setImageSrc] = useState(KlarmanAICard);
  return <div className="w-full h-full bg-white mb-10" data-sentry-component="Home" data-sentry-source-file="page.tsx">
      <Nav data-sentry-element="Nav" data-sentry-source-file="page.tsx" />
      <div className="flex flex-col justify-center mt-16 md:mt-32 bg-white">
        <div className="text-black text-center block sm:text-2xl md:text-3xl lg:text-5xl text-2xl font-[700] px-10 mb-6">
          Explore, Analyze, Visualize.
        </div>
        <div className="text-black text-center block sm:text-2xl md:text-3xl lg:text-5xl text-2xl font-[700] px-10 mb-6">
          With AI.
        </div>
        {/* <div className="text-black text-center hidden sm:block md:text-3xl lg:text-5xl text-2xl font-[700] px-10 mb-6">
          Play, Analyze, Visualize.
         </div>
         <div className="text-black text-center hidden sm:block md:text-3xl lg:text-5xl text-2xl font-[700] px-10 mb-6">
          With AI.
         </div> */}
        {/* <div className="text-black text-center block sm:hidden  md:text-3xl text-2xl font-[700] px-10 mb-6">
          <div>Play,</div>
          <div>Analyze,</div>
          <div>Visualize.</div>
          <div>With AI.</div>
         </div> */}
        <div className="text-black mt-4 text-center sm:block md:text-1xl lg:text-1xl text-1xl font-[500] px-10 mb-6">
          <div>Klarman is the AI-native data whiteboard.</div>
          <div>Explore any dataset and generate any output.</div>
        </div>
        <div className="flex justify-center mt-6 text-black mb-8">
          <Link className="bg-black hover:bg-hover-black text-white rounded-md p-2" href="https://airtable.com/apptQRcMxe1V2DXMh/pag2LjGPr51DuggPs/form" target="_blank" data-sentry-element="Link" data-sentry-source-file="page.tsx">
            Request Access{" -> "}
          </Link>
          {/* <Link
            className=" ml-4 hover:bg-sidebar-custom text-custom-blue rounded-md p-2"
            href="https://airtable.com/apptQRcMxe1V2DXMh/pagsCJaSSr36XnsWh/form"
            target="_blank"
           >
            See it in action{" -> "}
           </Link> */}
        </div>

        <div className="flex items-center justify-center pl-5 pr-5 mt-6">
          <Image src={imageSrc} alt="Card Image" className={`border border-gray-200 rounded-[0.3125rem] shadow-md`} width={600} height={360} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
        </div>
        <div className="flex w-full space-x-4 mt-6 lg:justify-center lg:p-0 pl-5 pr-5 justify-start overflow-x-auto hide-scrollbar text-black">
          <div className="w-44 h-44 bg-card-bg p-4 rounded-xl group hover:cursor-pointer border-[1.4px] border-gray-200 hover:bg-white flex flex-col items-start justify-between flex-shrink-0" onMouseEnter={() => setImageSrc(WhiteboardCard)}>
            <div className="flex-grow-0">
              <Image src={WhiteboardImg} alt="Card Image" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
              <div className="font-bold mt-3">Whiteboard</div>
            </div>
            <div className="flex-grow overflow-hidden">
              <p className="text-xs mt-3">
                Our infinite canvas, ideal to visually iterate and collaborate
              </p>
            </div>
            <div className="text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-100">
              {"->"}
            </div>
          </div>
          {/* <div
            className="w-44 h-44 bg-card-bg p-4 rounded-xl group hover:cursor-pointer border-[1.4px] border-gray-200 hover:bg-white flex flex-col items-start justify-between flex-shrink-0"
            onMouseEnter={() => setImageSrc(ComponentsCard)}
           >
            <div className="flex-grow-0">
              <Image
                src={ComponentsImg}
                alt="Card Image"
                width={24}
                height={24}
              />
              <div className="font-bold mt-3">Data Tools</div>
            </div>
            <div className="flex-grow overflow-hidden">
              <p className="text-xs mt-3">
                Powerful data analysis and visualization tools optimized for AI
              </p>
            </div>
            <div className="text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-100">
              {"->"}
            </div>
           </div> */}
          <div className="w-44 h-44 bg-card-bg p-4 rounded-xl group hover:cursor-pointer border-[1.4px] border-gray-200 hover:bg-white flex flex-col items-start justify-between flex-shrink-0" onMouseEnter={() => setImageSrc(KlarmanAICard)}>
            <div className="flex-grow-0">
              <Image src={KlarmanAiImg} alt="Card Image" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
              <div className="font-bold mt-3">AI-Native</div>
            </div>
            <div className="flex-grow overflow-hidden">
              <p className="text-xs mt-3">Designed to leverage AI at every step</p>
            </div>
            <div className="text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-100">
              {"->"}
            </div>
          </div>
          {/* <div
            className="w-44 h-44 bg-card-bg p-4 rounded-xl group hover:cursor-pointer border-[1.4px] border-gray-200 hover:bg-white flex flex-col items-start justify-between flex-shrink-0"
            onMouseEnter={() => setImageSrc(DataCard)}
           >
            <div className="flex-grow-0">
              <Image src={DataImg} alt="Card Image" width={24} height={24} />
              <div className="font-bold mt-3">Data</div>
            </div>
            <div className="flex-grow overflow-hidden">
              <p className="text-xs mt-3">
                Robust financial datasets optimized for Klarman AI
              </p>
            </div>
            <div className="text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-100">
              {"->"}
            </div>
           </div> */}
          <div className="w-44 h-44 bg-card-bg p-4 rounded-xl group hover:cursor-pointer border-[1.4px] border-gray-200 hover:bg-white flex flex-col items-start justify-between flex-shrink-0" onMouseEnter={() => setImageSrc(CollaborationCard)}>
            <div className="flex-grow-0">
              <Image src={CollaborateImg} alt="Card Image" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
              <div className="font-bold mt-3">Collaborate</div>
            </div>
            <div className="flex-grow overflow-hidden">
              <p className="text-xs mt-3">Work with your team in real-time</p>
            </div>
            <div className="text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-100">
              {"->"}
            </div>
          </div>
        </div>
        {/* <div className="bg-gradient-to-b from-white to-bg-card-bg py-16"> */}

        <div className="bg-gradient-to-b from-white to-card-bg pb-20">
          <div className="text-black mt-16 md:mt-32 text-center font-semibold lg:text-3xl md:text-2xl text-lg sm:text-lg px-10">
            Start playing with data
          </div>
          <div className="text-black text-center md:text-base text-sm sm:text-base mt-6 px-10 pb-6">
            <div>On Klarman you’ll find robust datasets and powerful</div>
            <div>AI agents ready to help you interact with data.</div>
          </div>

          <div className="flex justify-between items-center mt-6 px-5 max-w-screen-lg mx-auto w-full">
            <div className="text-black font-semibold md:text-lg text-base">Datasets</div>
            <Link href="/login" className="text-black font-semibold text-base hover:underline flex items-center" data-sentry-element="Link" data-sentry-source-file="page.tsx">
              {"->"} View all
            </Link>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-3 pl-5 pr-5 max-w-screen-lg mx-auto w-full">
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                {/* <Image src={WhiteboardImg} alt="Dataset" width={24} height={24} /> */}
                <div className="font-semibold text-black">🏀&ensp; NBA Player Data</div>
              </div>
              <div className="text-xs text-black">
                Comprehensive statistics and performance data for NBA players, including historical
                and current season metrics.
              </div>
            </div>
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                {/* <Image src={WhiteboardImg} alt="Dataset" width={24} height={24} /> */}
                <div className="font-semibold text-black">🏅&ensp; Olympic Medals</div>
              </div>
              <div className="text-xs text-black">
                Detailed records of Olympic medal counts, athletes, and events, covering various
                games and disciplines.
              </div>
            </div>
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                {/* <Image src={WhiteboardImg} alt="Dataset" width={24} height={24} /> */}
                <div className="font-semibold text-black">🎥&ensp; Movie Ratings</div>
              </div>
              <div className="text-xs text-black">
                Aggregated movie ratings and reviews from multiple sources, including critic and
                audience scores.
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-6 px-5 max-w-screen-lg mx-auto w-full">
            <div className="text-black font-semibold md:text-lg text-base">Agents</div>
            <Link href="/login" className="text-black font-semibold text-base hover:underline flex items-center" data-sentry-element="Link" data-sentry-source-file="page.tsx">
              {"->"} View all
            </Link>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-3 pl-5 pr-5 max-w-screen-lg mx-auto w-full">
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                {/* <Image src={WhiteboardImg} alt="Dataset" width={24} height={24} /> */}
                <div className="font-semibold text-black">💽&ensp; Data</div>
              </div>
              <div className="text-xs text-black">
                A versatile data agent capable of processing, processing, and preparing datasets for
                further analysis.
              </div>
            </div>
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                {/* <Image src={WhiteboardImg} alt="Dataset" width={24} height={24} /> */}
                <div className="font-semibold text-black">📊&ensp; Charting</div>
              </div>
              <div className="text-xs text-black">
                An intuitive tool for creating, customizing, and visualizing data through various
                types of charts and graphs.
              </div>
            </div>
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                <Image src={ExportIcon} alt="Dataset" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
                <div className="ml-2 font-semibold text-black">Datagrid</div>
              </div>
              <div className="text-xs text-black">
                A robust agent for managing, filtering, and manipulating large datasets in a tabular
                format.
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-6 px-5 max-w-screen-lg mx-auto w-full">
            <div className="text-black font-semibold md:text-lg text-base">Models</div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-3 pl-5 pr-5 max-w-screen-lg mx-auto w-full">
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                <Image src={OpenaiIcon} alt="Dataset" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
                <div className="ml-2 font-semibold text-black">GPT</div>
              </div>
              <div className="text-xs text-black pb-2">OpenAI</div>
              <div className="text-xs text-black">
                Cutting-edge LLM for generating text, performing natural language processing tasks,
                and more.
              </div>
            </div>
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                <Image src={MetaIcon} alt="Dataset" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
                <div className="ml-2 font-semibold text-black">Llama</div>
              </div>
              <div className="text-xs text-black pb-2">Meta</div>
              <div className="text-xs text-black">
                Advanced AI model designed for complex data processing and machine learning tasks.
              </div>
            </div>
            <div className="p-4 rounded-xl border-[1.4px] border-gray-400 h-32">
              <div className="flex items-center mb-2">
                <Image src={AnthropicIcon} alt="Dataset" width={24} height={24} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
                <div className="ml-2 font-semibold text-black">Claude</div>
              </div>
              <div className="text-xs text-black pb-2">Anthropic</div>
              <div className="text-xs text-black">
                An ethical AI model, focused on providing safe, reliable, and human-centered data.
              </div>
            </div>
          </div>
        </div>

        <div className="text-black mt-16 md:mt-32  text-center lg:text-3xl md:text-2xl text-lg sm:text-lg font-semibold pl-2 pr-2">
          Klarman for Enterprises
        </div>
        <div className="text-black md:text-base text-sm sm:text-base  text-center mt-6 px-10 mb-6">
          <div>We are helping teams use Klarman on enterprise datasets.</div>
          <div>Get in touch if you would like to become an enterprise pilot customer.</div>
        </div>
        <div className="flex mt-6 md:flex-row flex-col items-center gap-2 justify-center text-black pl-5 pr-5 mb-6">
          <div className="w-80 h-50 md:h-40 bg-card-bg p-4 rounded flex flex-col justify-left items-left ">
            <div className="font-semibold mb-2">Financial Datasets</div>
            <div className="text-xs">
              <div className="list-disc list-inside">
                <div className="ml-1 flex flex-col  relative ">
                  <span className="absolute left-[-0.2em] text-black">•</span>
                  <div className="ml-3">
                    Access to robust financial datasets from the largest financial data providers
                  </div>
                </div>
                <div className="ml-1 flex flex-col  relative ">
                  <span className="absolute left-[-0.2em] mt-1 text-black">•</span>
                  <div className="ml-3 mt-1">Bloomberg, S&P Capital IQ, Factset, and more</div>
                </div>
                <div className="ml-1 flex flex-col  relative ">
                  <span className="absolute left-[-0.2em] mt-1 text-black">•</span>
                  <div className="ml-3 mt-1">Targeted financial AI agents for analyst work</div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-80 h-50 md:h-40 bg-card-bg p-4 rounded flex flex-col justify-left items-left ">
            <div className="font-semibold mb-2">Custom Datasets</div>
            <div className="text-xs">
              <div className="list-disc list-inside">
                <div className="ml-1 flex flex-col relative ">
                  <span className="absolute left-[-0.2em] text-black">•</span>
                  <div className="ml-3">
                    Connect to any custom datasets, structured or unstructured
                  </div>
                </div>
                <div className="ml-1 flex flex-col  relative ">
                  <span className="absolute left-[-0.2em] mt-1 text-black">•</span>
                  <div className="ml-3 mt-1">
                    Documents, data rooms, Snowflake, Databricks, etc.
                  </div>
                </div>
                <div className="ml-1 flex flex-col relative ">
                  <span className="absolute left-[-0.2em] mt-1 text-black">•</span>
                  <div className="ml-3 mt-1">Custom AI agents for workflows</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-6 text-black">
          <Link className="bg-black hover:bg-hover-black text-white rounded-md p-2" href="https://airtable.com/apptQRcMxe1V2DXMh/pagvAOzAferbVGcKa/form" target="_blank" data-sentry-element="Link" data-sentry-source-file="page.tsx">
            Get in Touch{" -> "}
          </Link>
          {/* <Link
            className=" ml-4 hover:bg-sidebar-custom text-custom-blue rounded-md p-2"
            href="https://airtable.com/apptQRcMxe1V2DXMh/pagsCJaSSr36XnsWh/form"
            target="_blank"
           >
            See it in action{" -> "}
           </Link> */}
        </div>
      </div>
      <Footer data-sentry-element="Footer" data-sentry-source-file="page.tsx" />
    </div>;
}